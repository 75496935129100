import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Switch from "../components/switch";
import styles from '../styles/pricing.module.css'

import GetStarted from "../components/getStarted"

let icons = require('../components/icons')

class Pricing extends React.Component {
    
    constructor(props) {
        super(props)

        this.state=({
            monthlyPlan: true,
        })

        this.handleToggle = this.handleToggle.bind(this)
        this.handleLabelClick = this.handleLabelClick.bind(this)
    }

    handleToggle() {        
        this.setState({
            monthlyPlan: !this.state.monthlyPlan,
        })
    }

    handleLabelClick(status) {
        if (this.state.monthlyPlan !== status) {
            this.handleToggle()
        }
    }

    render() {
    	return (
    		<Layout isNavigationTransparent={true} isHomePage={false}>
    			<SEO
    				title="Poirot Pricing"
    				description="Instantly find anyone's email address. Backed by industry-leading email lookup & verification technology, Poirot helps your sales and lead generation efforts."
    				keywords={[
    					`Poirot`,
    					`Poirot Features`,
    					`Email Lookup Tool`,
    					`Email Finder`,
    					`Email Checker`,
    				]}
    			/>
    			<div className={styles.headerBlockWrapper}>
    				<div className="sectionWrapper1200">
    					<div className={styles.headerBlock}>
    						<div className={styles.pricingHeaderMessage}>
    							<h1>Simple and transparent pricing</h1>
                                <div>
        							<div>
                                        <h2>
        								    Choose the plan that's right for your business.
            							</h2>
                                    </div>
                                    <div>
                                        <h3>
                                            Whether you're just getting started or you're looking to improve your email related activities, we've got you covered. We are charging only for emails we are sure won't bounce and will reach the receiver's inbox.
                                        </h3>
                                    </div>
                                </div>
    						</div>

    					</div>
    				</div>
    			</div>
    			<div className={styles.pricingWrapper}>
                    <div className={'sectionWrapper1200'}>
                        <div className={styles.cycleSwitchWrapper}>
                            <div className={styles.cycleSwitch}>
                                <div className={styles.toggleLabel} onClick={() => this.handleLabelClick(true)}>
                                    <div><h4>Monthly</h4></div>
                                </div>
                                <div>
                                    <Switch isOn={!this.state.monthlyPlan} handleToggle={() => this.handleToggle()}/>
                                </div>
                                <div className={styles.toggleLabel} onClick={() => this.handleLabelClick(false)}>
                                    <div><h4>Yearly</h4></div>
                                    <div><span>2 MONTHS FOR FREE</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.pricing}>

                            <div className={styles.priceBox}>
                                <div><h1>Enterprise</h1></div>
                                <div><hr /></div>
                                <div className={styles.price}>
                                    {
                                        this.state.monthlyPlan ?
                                        <React.Fragment><span>$399</span>/month</React.Fragment>
                                        :
                                        <React.Fragment><span>$3,990</span>/year</React.Fragment>
                                    }
                                </div>
                                <div><hr /></div>
                                <div className={styles.credits}>
                                    <div><span>30,000</span>Lookup credits</div>
                                    <div><span>60,000</span>Verification credits</div>
                                </div>
                                <div><hr /></div>
                                <div className={styles.planFeatures}>
                                    <div>
                                        <span>Teams</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>Bulk usage</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>API usage</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>CSV export</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.priceBox}>
                                <div><h1>Accelerate</h1></div>
                                <div><hr /></div>
                                <div className={styles.price}>
                                    {
                                        this.state.monthlyPlan ?
                                        <React.Fragment><span>$249</span>/month</React.Fragment>
                                        :
                                        <React.Fragment><span>$2,490</span>/year</React.Fragment>
                                    }
                                </div>
                                <div><hr /></div>
                                <div className={styles.credits}>
                                    <div><span>15,000</span>Lookup credits</div>
                                    <div><span>30,000</span>Verification credits</div>
                                </div>
                                <div><hr /></div>
                                <div className={styles.planFeatures}>
                                    <div>
                                        <span>Teams</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>Bulk usage</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>API usage</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>CSV export</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={`${styles.priceBox} ${styles.priceBoxFeatured}`}>
                                <div><h1>Growth</h1></div>
                                <div><hr /></div>
                                <div className={styles.price}>
                                    {
                                        this.state.monthlyPlan ?
                                        <React.Fragment><span>$99</span>/month</React.Fragment>
                                        :
                                        <React.Fragment><span>$990</span>/year</React.Fragment>
                                    }
                                </div>
                                <div><hr /></div>
                                <div className={styles.credits}>
                                    <div><span>5,000</span>Lookup credits</div>
                                    <div><span>10,000</span>Verification credits</div>
                                </div>
                                <div><hr /></div>
                                <div className={styles.planFeatures}>
                                    <div>
                                        <span>Teams</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>Bulk usage</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>API usage</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>CSV export</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.priceBox}>
                                <div><h1>Personal</h1></div>
                                <div><hr /></div>
                                <div className={styles.price}>
                                    {
                                        this.state.monthlyPlan ?
                                        <React.Fragment><span>$19</span>/month</React.Fragment>
                                        :
                                        <React.Fragment><span>$190</span>/year</React.Fragment>
                                    }
                                </div>
                                <div><hr /></div>
                                <div className={styles.credits}>
                                    <div><span>500</span>Lookup credits</div>
                                    <div><span>1,000</span>Verification credits</div>
                                </div>
                                <div><hr /></div>
                                <div className={styles.planFeatures}>
                                    <div>
                                        <span>Teams</span>
                                        <div>{icons.timesCircle()}</div>
                                    </div>
                                    <div>
                                        <span>Bulk usage</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                    <div>
                                        <span>API usage</span>
                                        <div>{icons.timesCircle()}</div>
                                    </div>
                                    <div>
                                        <span>CSV export</span>
                                        <div>{icons.checkCircle()}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.priceBox}>
                                <div><h1>Free</h1></div>
                                <div><hr /></div>
                                <div className={styles.price}>
                                    {
                                        this.state.monthlyPlan ?
                                        <React.Fragment><span>$0</span>/month</React.Fragment>
                                        :
                                        <React.Fragment><span>$0</span>/year</React.Fragment>
                                    }
                                </div>
                                <div><hr /></div>
                                <div className={styles.credits}>
                                    <div><span>20</span>Lookup credits</div>
                                    <div><span>100</span>Verification credits</div>
                                </div>
                                <div><hr /></div>
                                <div className={styles.planFeatures}>
                                    <div>
                                        <span>Teams</span>
                                        <div>{icons.timesCircle()}</div>
                                    </div>
                                    <div>
                                        <span>Bulk usage</span>
                                        <div>{icons.timesCircle()}</div>
                                    </div>
                                    <div>
                                        <span>API usage</span>
                                        <div>{icons.timesCircle()}</div>
                                    </div>
                                    <div>
                                        <span>CSV export</span>
                                        <div>{icons.timesCircle()}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <hr />

                <GetStarted />

    		</Layout>
    	)
    }
}

export default Pricing