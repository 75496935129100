import React from 'react';

import styles from '../styles/pricing.module.css'

const Switch = ({ isOn, handleToggle }) => {
    return (
        <React.Fragment>
            <input checked={isOn} onChange={handleToggle} className={styles.reactSwitchCheckbox} id={"react-switch"} type="checkbox" />
            <label className={styles.reactSwitchLabel} htmlFor={"react-switch"}>
                <span className={styles.reactSwitchButton} />
            </label>
        </React.Fragment>
    );
};

export default Switch;