import React from "react";

export const checkCircle = () => (
	<svg
		className="checkCircle"
		height="28"
		viewBox="0 0 28 28"
		width="28"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m13.991 28c7.646 0 14.009-6.35 14.009-14 0-7.651-6.38-14-14.026-14-7.629 0-13.974 6.349-13.974 14 0 7.65 6.363 14 13.991 14z"
			fill="#a4d037"
		/>
		<path
			d="m12.309 20.8c-.52 0-.918-.225-1.334-.728l-3.624-4.58c-.225-.295-.347-.625-.347-.972 0-.676.52-1.232 1.196-1.232.417 0 .781.174 1.145.642l2.895 3.886 5.86-9.472c.278-.468.676-.711 1.075-.711.642 0 1.266.434 1.266 1.145 0 .347-.174.694-.382 1.006l-6.501 10.288c-.312.485-.728.728-1.249.728z"
			fill="#fff"
		/>
	</svg>
);

export const timesCircle = () => (
	<svg
		className="timesCircle"
		height="28"
		viewBox="0 0 28 28"
		width="28"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m13.991 28c7.646 0 14.009-6.35 14.009-14 0-7.651-6.38-14-14.026-14-7.629 0-13.974 6.349-13.974 14 0 7.65 6.363 14 13.991 14z"
			fill="#f05230"
		/>
		<path
			d="m9.414 19.846c-.676 0-1.231-.572-1.231-1.266 0-.33.139-.625.364-.85l3.693-3.713-3.693-3.712c-.225-.226-.364-.521-.364-.85 0-.694.555-1.215 1.231-1.215.347 0 .624.104.85.347l3.71 3.713 3.762-3.73c.26-.26.52-.365.85-.365.676 0 1.231.538 1.231 1.232 0 .33-.122.607-.364.85l-3.711 3.73 3.693 3.695c.226.243.364.521.364.868 0 .694-.554 1.266-1.231 1.266-.364 0-.659-.156-.884-.381l-3.71-3.713-3.693 3.713c-.208.242-.52.381-.867.381z"
			fill="#fff"
		/>
	</svg>
);